import { useSession } from '@/hooks';
import { signOut } from '@holo/auth/react';
import { useCurrentUserQuery } from '@holo/gql';
import { Link } from '@holo/link';
import { Avatar, Button, Popover, Text, XStack, YStack } from '@holo/ui';
import { useRouter } from 'next/router';
import { forwardRef, useCallback, useState } from 'react';

const UserMenu = () => {
  const { asPath } = useRouter();
  const { user } = useSession();
  const [isLogingOut, setIsLogingOut] = useState(false);

  const { data, loading } = useCurrentUserQuery({
    skip: !user,
  });

  const handleSignOut = useCallback(async () => {
    setIsLogingOut(true);
    await signOut({ callbackUrl: '/login' });
  }, []);

  // Remove potential wholesale/retail from the path and the first slash
  const loginCallbackUrl = asPath.replaceAll('/wholesale', '').replaceAll('/retail', '').substring(1);

  if (!user) {
    return (
      <Link href={loginCallbackUrl ? `/login?return_to=${loginCallbackUrl}` : '/login'}>
        <Button icon="user" iconSize={4 * 5} label="Open user menu" variant="quartenary" width="$10" height="$10" />
      </Link>
    );
  }

  return (
    <Popover
      trigger={forwardRef((props, ref) => (
        <YStack {...props} ref={ref} cursor="pointer">
          <Avatar size={40} loading={loading} firstName={data?.user?.firstName} lastName={data?.user?.lastName} />
        </YStack>
      ))}
      placement="top-end"
    >
      <YStack
        className="user-menu-animation"
        style={{
          // @ts-ignore
          boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);',
        }}
        width={250}
        tag="nav"
        mt="$3"
        borderRadius={8}
        overflow="hidden"
        backgroundColor="$background"
        borderWidth={1}
        borderColor="$border"
      >
        <XStack tag="li" ai="center" p="$4" borderBottomWidth={1} borderColor="$border">
          <YStack ml="$1" flex={1}>
            <Text
              color="$contrast"
              fontWeight="$medium"
              fontSize="$14"
              loading={loading}
              mb="$1"
              numberOfLines={1}
              alignSelf="flex-start"
            >
              {loading ? 'Loading name' : data?.user?.team?.name || data?.user?.firstName}
            </Text>

            <Text color="$darkGray" fontSize="$12" loading={loading} numberOfLines={1} alignSelf="flex-start">
              {loading ? 'Loading email' : data?.user?.email}
            </Text>
          </YStack>
        </XStack>

        <XStack tag="li">
          <Link href="/account" style={{ flex: 1 }}>
            <Text
              display="flex"
              py="$3"
              ai="center"
              px="$4"
              color="$contrast"
              fontSize="$14"
              hoverStyle={{
                backgroundColor: '$subtlePale',
                color: '$contrast',
              }}
            >
              Account
            </Text>
          </Link>
        </XStack>

        <XStack tag="li">
          <Link href="/orders" style={{ flex: 1 }}>
            <Text
              display="flex"
              py="$3"
              ai="center"
              px="$4"
              color="$contrast"
              fontSize="$14"
              hoverStyle={{
                backgroundColor: '$subtlePale',
                color: '$contrast',
              }}
            >
              Orders
            </Text>
          </Link>
        </XStack>

        <XStack tag="li">
          <Link href="/billing" style={{ flex: 1 }}>
            <Text
              display="flex"
              py="$3"
              ai="center"
              px="$4"
              color="$contrast"
              fontSize="$14"
              hoverStyle={{
                backgroundColor: '$subtlePale',
                color: '$contrast',
              }}
            >
              Billing
            </Text>
          </Link>
        </XStack>

        {user?.level === 'wholesale' && (
          <XStack tag="li" borderTopWidth={1} borderColor="$border">
            <Link href="/team" style={{ flex: 1 }}>
              <Text
                display="flex"
                ai="center"
                px="$4"
                py="$3"
                color="$contrast"
                fontSize="$14"
                hoverStyle={{
                  backgroundColor: '$subtlePale',
                  color: '$contrast',
                }}
              >
                Team
              </Text>
            </Link>
          </XStack>
        )}

        <XStack tag="li" borderTopWidth={1} borderColor="$border">
          {/* TODO: Use stripped Button */}
          <Text
            flex={1}
            cursor="pointer"
            onPress={handleSignOut}
            display="flex"
            ai="center"
            accessibilityRole="button"
            px="$4"
            py="$3"
            color="$contrast"
            fontSize="$14"
            hoverStyle={{ backgroundColor: '$subtlePale', color: '$contrast' }}
          >
            {isLogingOut ? 'Logging out...' : 'Log out'}
          </Text>
        </XStack>
      </YStack>
    </Popover>
  );
};

export default UserMenu;
