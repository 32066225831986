import { Popover as HUPopover } from '@headlessui/react';
import type { Placement } from '@popperjs/core';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

type PopoverInnerProps = {
  children: React.ReactNode | ((handlers: { close: () => void }) => React.ReactNode);
  close: () => void;
};

const PopoverInner = ({ children, close }: PopoverInnerProps) => {
  const { events } = useRouter();

  useEffect(() => {
    const handleClose = () => close();
    events.on('routeChangeComplete', handleClose);

    return () => {
      events.off('routeChangeComplete', handleClose);
    };
  }, []);

  return <>{typeof children === 'function' ? children({ close }) : children}</>;
};

type Props = {
  trigger: React.ElementType<any>;
  children: React.ReactNode | ((handlers: { close: () => void }) => React.ReactNode);
  placement?: Placement;
};

const Popover = ({ trigger: ButtonElement, children, placement = 'bottom' }: Props) => {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });

  return (
    <HUPopover style={{ zIndex: 10 }} className="popover-animation">
      {({ close }) => (
        <>
          {/* @ts-ignore */}
          <HUPopover.Button ref={(node: any) => setReferenceElement(node)} as={ButtonElement} />
          <HUPopover.Panel style={styles.popper} ref={(node: any) => setPopperElement(node)} {...attributes.popper}>
            <PopoverInner close={close}>{typeof children === 'function' ? children({ close }) : children}</PopoverInner>
          </HUPopover.Panel>
        </>
      )}
    </HUPopover>
  );
};

export default Popover;
