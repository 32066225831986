import CartButton from '@/components/CartButton';
import UserMenu from '@/components/UserMenu/UserMenu';
import { Link } from '@holo/link';
import { Button, Container, Icon, ListItem, Popover, Text, VisuallyHidden, XStack, YStack } from '@holo/ui';
import { Logo } from '@holo/ui/assets';
import Image from 'next/image';
import { useRouter } from 'next/router';
import platform from 'public/img/platform.png';
import { forwardRef } from 'react';
import ScrollableAwareProductSearch from '../ScrollableAwareProductSearch/ScrollableAwareProductSearch';

const INCLUDE_FOR_PRODUCERS_LINK_ROUTES = ['/'];
const EXCLUDE_CART_BUTTON_ROUTES = ['/checkout', '/login', '/login/email'];
const EXCLUDE_USER_MENU_ROUTES = ['/login', '/login/email'];

const ProducersMenu = forwardRef((props, ref) => {
  return (
    // @ts-ignore
    <XStack alignItems="center" ml="$5" ref={ref} cursor="pointer" {...props}>
      <XStack jc="center">
        <Text mr="$1">For producers</Text>
        <Icon icon="chevronDown" size={16} color="$darkGray" />
      </XStack>
    </XStack>
  );
});

const DesktopHeader = () => {
  const { route } = useRouter();
  const shouldRenderForProducersLink = INCLUDE_FOR_PRODUCERS_LINK_ROUTES.includes(route);
  const shouldRenderCartButton = !EXCLUDE_CART_BUTTON_ROUTES.includes(route);
  const shouldRenderUserMenu = !EXCLUDE_USER_MENU_ROUTES.includes(route);
  const shouldRenderSignUpLink = EXCLUDE_USER_MENU_ROUTES.includes(route);
  const shouldRenderProductSearch = route === '/[store]/retail' || route === '/[store]/wholesale';

  return (
    <YStack
      className="header"
      // @ts-ignore
      position="sticky"
      top={0}
      zIndex={99}
      backgroundColor="hsla(0, 0%, 100%, .8)"
    >
      <XStack display="none" $md={{ display: 'flex' }} zIndex={99} py="$4" alignItems="center">
        <Container flexDirection="row" alignItems="center" space="$4">
          <Link href="/">
            <VisuallyHidden>
              <Text>Go to homepage</Text>
            </VisuallyHidden>
            <Logo />
          </Link>

          <XStack ml="auto" alignItems="center" space="$5">
            {shouldRenderForProducersLink && (
              <Popover trigger={ProducersMenu} placement="top-start">
                <XStack
                  className="navigation-menu-animation"
                  style={{
                    // @ts-ignore
                    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
                  }}
                  tag="nav"
                  mt="$8"
                  borderRadius="$1"
                  backgroundColor="$background"
                  borderWidth={1}
                  borderColor="$border"
                  overflow="hidden"
                  p="$4"
                >
                  <YStack width={210} jc="flex-end" borderRadius="$1" mr="$2" cursor="pointer">
                    <Link
                      href="/platform"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Image
                        src={platform}
                        fill
                        alt="background"
                        style={{
                          backgroundColor: '#e8f1ea',
                          position: 'absolute',
                          objectFit: 'cover',
                          borderRadius: '6px 6px 0 0',
                          overflow: 'hidden',
                        }}
                        quality={100}
                      />
                      <YStack paddingVertical="$3" backgroundColor="white">
                        <XStack mb="$2" alignItems="center">
                          <Icon icon="layers" size={18} />
                          <Text fontWeight="$medium" ml="$2">
                            Platform
                          </Text>
                        </XStack>
                        <Text fontSize="$13" color="$darkGray">
                          One platform for all your farm and sales operations
                        </Text>
                      </YStack>
                    </Link>
                  </YStack>
                  <YStack tag="ul">
                    <ListItem jc="flex-start" p="$2" hoverStyle={{ backgroundColor: '$subtlePale' }} borderRadius="$1">
                      <Link href="/#marketplace">
                        <YStack p="$1" width={150}>
                          <XStack mb="$2" alignItems="center">
                            <Icon icon="lineChart" size={18} />
                            <Text fontWeight="$medium" ml="$2">
                              Marketplace
                            </Text>
                          </XStack>
                          <Text fontSize="$13" color="$darkGray">
                            Sell to both retail and wholesale customers
                          </Text>
                        </YStack>
                      </Link>
                    </ListItem>

                    <ListItem jc="flex-start" p="$2" hoverStyle={{ backgroundColor: '$subtlePale' }} borderRadius="$1">
                      <Link href="/#task-manager">
                        <YStack p="$1" width={150}>
                          <XStack mb="$2" alignItems="center">
                            <Icon icon="checkSquare" size={18} />
                            <Text fontWeight="$medium" ml="$2">
                              Task manager
                            </Text>
                          </XStack>
                          <Text fontSize="$13" color="$darkGray">
                            Supercharge productivity with task manager
                          </Text>
                        </YStack>
                      </Link>
                    </ListItem>

                    <ListItem jc="flex-start" p="$2" hoverStyle={{ backgroundColor: '$subtlePale' }} borderRadius="$1">
                      <Link href="/#crop-planner">
                        <YStack p="$1" width={150}>
                          <XStack mb="$2" alignItems="center">
                            <Icon icon="crop" size={18} />
                            <Text fontWeight="$medium" ml="$2">
                              Crop planner
                            </Text>
                          </XStack>
                          <Text fontSize="$13" color="$darkGray">
                            Brings your fields online
                          </Text>
                        </YStack>
                      </Link>
                    </ListItem>
                  </YStack>

                  <YStack tag="ul">
                    <ListItem jc="flex-start" p="$2" hoverStyle={{ backgroundColor: '$subtlePale' }} borderRadius="$1">
                      <Link href="/#harvesting">
                        <YStack p="$1" width={150}>
                          <XStack mb="$2" alignItems="center">
                            <Icon icon="sprout" size={18} />
                            <Text fontWeight="$medium" ml="$2">
                              Harvesting
                            </Text>
                          </XStack>
                          <Text fontSize="$13" color="$darkGray">
                            Automate your harvest workflows
                          </Text>
                        </YStack>
                      </Link>
                    </ListItem>

                    <ListItem jc="flex-start" p="$2" hoverStyle={{ backgroundColor: '$subtlePale' }} borderRadius="$1">
                      <Link href="/#order-packing">
                        <YStack p="$1" width={150}>
                          <XStack mb="$2" alignItems="center">
                            <Icon icon="package" size={18} />
                            <Text fontWeight="$medium" ml="$2">
                              Order packing
                            </Text>
                          </XStack>
                          <Text fontSize="$13" color="$darkGray">
                            Increase efficiency and accuracy
                          </Text>
                        </YStack>
                      </Link>
                    </ListItem>

                    <ListItem jc="flex-start" p="$2" hoverStyle={{ backgroundColor: '$subtlePale' }} borderRadius="$1">
                      <Link href="/#soil-health">
                        <YStack p="$1" width={150}>
                          <XStack mb="$2" alignItems="center">
                            <Icon icon="activity" size={18} />
                            <Text fontWeight="$medium" ml="$2">
                              Soil health
                            </Text>
                          </XStack>
                          <Text fontSize="$13" color="$darkGray">
                            Get on top of your soil health
                          </Text>
                        </YStack>
                      </Link>
                    </ListItem>
                  </YStack>
                </XStack>
              </Popover>
            )}

            {shouldRenderProductSearch && <ScrollableAwareProductSearch />}

            {(shouldRenderForProducersLink || shouldRenderCartButton) && (
              <XStack ai="center" space="$4">
                {shouldRenderCartButton && <CartButton />}
              </XStack>
            )}

            {shouldRenderSignUpLink && (
              <Link href="/signup">
                <Button variant="quartenary" cursor="pointer" opacity={1}>
                  Sign up
                </Button>
              </Link>
            )}
            {shouldRenderUserMenu && <UserMenu />}
          </XStack>
        </Container>
      </XStack>
    </YStack>
  );
};

export default DesktopHeader;
