import { skeleton } from '../../skeleton';
import Text from '../Text/Text';
import YStack from '../YStack/YStack';

type Props = {
  size?: number;
  firstName?: string | null;
  lastName?: string | null;
  loading?: boolean;
};

const Avatar = ({ size = 35, firstName, lastName, loading }: Props) => {
  return (
    <YStack
      width={size}
      height={size}
      borderRadius={9999}
      backgroundColor="$subtlePale"
      jc="center"
      ai="center"
      className={skeleton.className('circle', loading)}
    >
      <Text fontSize="$10" color="$darkGray" fontWeight="$medium">
        {firstName?.charAt(0)}
        {lastName?.charAt(0)}
      </Text>
    </YStack>
  );
};

export default Avatar;
