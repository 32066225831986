import { useFilteredProducts } from '@/hooks';
import { Input, type InputProps } from '@holo/ui';

type Props = InputProps;

const ProductSearchFilter = (props: Props) => {
  const { set, value } = useFilteredProducts();

  return (
    <Input
      value={value}
      placeholder="Search"
      paddingLeft="$8"
      iconBefore="search"
      variant="rounded"
      backgroundColor="$subtlePale"
      borderWidth={0}
      height={32}
      paddingRight={0}
      onChangeText={set}
      {...props}
    />
  );
};

export default ProductSearchFilter;
