import { motion, useScroll, useTransform } from 'framer-motion';
import ProductSearchFilter from '../ProductSearchFilter/ProductSearchFilter';

const ScrollableAwareProductSearch = () => {
  const { scrollY } = useScroll();

  const opacity = useTransform(scrollY, [350, 400], [0, 1]);
  const pointerEvents = useTransform(opacity, (latest) => {
    return latest > 0.2 ? 'auto' : 'none';
  });

  return (
    <motion.div style={{ opacity, pointerEvents }} initial={{ opacity: 0 }}>
      <ProductSearchFilter />
    </motion.div>
  );
};

export default ScrollableAwareProductSearch;
