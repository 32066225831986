// @ts-nocheck
import { YStack } from '@holo/ui';
import ActiveCartsItem from './ActiveCartsItem';

const ActiveCartsItemList = ({ items }: Props) => {
  return (
    <YStack paddingHorizontal="$5">
      {items.map((item) => (
        <ActiveCartsItem key={item?.node?.id} {...item?.node} />
      ))}
    </YStack>
  );
};

export default ActiveCartsItemList;
