import { useCarts, useDrawer } from '@/hooks';
import { Button, Icon, Text } from '@holo/ui';
import { useTranslation } from 'next-i18next';

const ActiveCartsButton = () => {
  const { t } = useTranslation();
  const { open } = useDrawer('active-carts');
  const { carts } = useCarts();

  return (
    <Button
      variant="secondary"
      onPress={open}
      disabled={!carts?.edges.length}
      cursor={carts?.edges.length ? 'pointer' : 'none'}
      borderWidth={1}
      borderColor="$border"
      hoverStyle={{ borderColor: '$border', backgroundColor: '$transparent' }}
      focusStyle={{ borderColor: '$border', backgroundColor: '$transparent' }}
      opacity={1}
    >
      <Text color="$contrast" fontWeight="$semi-bold" display="flex" space="$2" alignItems="center">
        <Icon icon="shoppingCart" size={14} />
        {t('activeCartsButton.title', { count: carts?.edges.length || 0 })}
      </Text>
    </Button>
  );
};

export default ActiveCartsButton;
