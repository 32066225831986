import { ActiveCartsButton } from '@/components/ActiveCarts';
import { SingleCartButton } from '@/components/Cart';
import { useStoreId } from '@/hooks';

const CartButton = () => {
  const storeId = useStoreId();

  // In a single store context
  if (storeId) {
    return <SingleCartButton />;
  }

  return <ActiveCartsButton />;
};

export default CartButton;
